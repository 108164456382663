import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import theme from 'styled-theming';
import { cwTheme } from '../theme/theme.js';

const Wrapper = styled.div`
	display: ${({ open }) => (open ? 'block' : 'none')};
	height: 100%;
	width: 100%;
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	background-color: rgb(55, 197, 235);
	overflow-x: hidden;
	transition: 0.5s;
`;

const InnerWrapper = styled.div`
	position: relative;
	top: 25%;
	width: 100%;
	text-align: center;
	margin-top: 30px;
	a {
		padding: 8px;
		text-decoration: none;
		font-size: 36px;
		color: rgba(255, 255, 255, 1);
		display: block;
		transition: 0.3s;
	}
	a:hover,
	a:focus {
		color: rgba(255, 255, 255, .75);
	}
`;

const MobileMenu = ({ open }) => {
	return (
		<Wrapper open={open}>
			<InnerWrapper>
				<Link activeStyle={{ color: cwTheme.secondaryBlue }} to="/">
					Home
				</Link>
				<Link activeStyle={{ color: cwTheme.secondaryBlue }} partiallyActive={true} to="/offerings">
					Offerings
				</Link>
				<Link activeStyle={{ color: cwTheme.secondaryBlue }} partiallyActive={true} to="/ondemand">
					On Demand
				</Link>
				<Link activeStyle={{ color: cwTheme.secondaryBlue }} partiallyActive={true} to="/live">
					Live
				</Link>
				<Link activeStyle={{ color: cwTheme.secondaryBlue }} partiallyActive={true} to="/blog">
					Blog
				</Link>
			</InnerWrapper>
		</Wrapper>
	);
};

export default MobileMenu;
