import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styles from './styles/layout.module.css';
import Header from './Header';
import Footer from './Footer';
import styled, { ThemeProvider } from 'styled-components';
import SEO from './Seo';
import theme from 'styled-theming';

const AppRoot = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	min-height: 100%;
`;

const MainAppContainer = styled.div`flex-grow: 1;`;

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);
	return (
		<AppRoot>
			<SEO />
			<Header siteTitle={data.site.siteMetadata.title} />
			<MainAppContainer>{children}</MainAppContainer>
			<Footer />
		</AppRoot>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
