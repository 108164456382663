import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import { cwTheme } from '../theme/theme.js';

const ContainerWrapper = styled.div`
	padding: 0 1rem;
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
`;

const Container = ({ children }) => {
	return <ContainerWrapper>{children}</ContainerWrapper>;
};

export default Container;
