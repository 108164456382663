import React from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import theme from 'styled-theming';
import { cwTheme } from '../theme/theme.js';

const hamburgerColor = theme('mode', {
	light: cwTheme.primaryBlue,
	dark: cwTheme.white
});

const HamButton = styled.button`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 2rem;
	height: 2rem;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	z-index: 10;

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		display: ${({ open }) => (open ? 'flex' : 'none')};
	}

	&:focus {
		outline: none;
	}

	div {
		width: 2rem;
		height: 0.25rem;
		background: ${({ open }) => (open ? cwTheme.white : hamburgerColor)};
		border-radius: 10px;
		transition: all 0.3s linear;
		position: relative;
		transform-origin: 1px;

		:first-child {
			transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
		}

		:nth-child(2) {
			opacity: ${({ open }) => (open ? '0' : '1')};
			transform: ${({ open }) => (open ? 'translateX(5px)' : 'translateX(0)')};
		}

		:nth-child(3) {
			transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
		}
	}
`;

const Hamburger = ({ open, setOpen }) => {
	return (
		<HamButton open={open} onClick={() => setOpen(!open)}>
			<div />
			<div />
			<div />
		</HamButton>
	);
};

Hamburger.propTypes = {
	open: bool.isRequired,
	setOpen: func.isRequired
};

export default Hamburger;
