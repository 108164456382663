import LogoWhite from '../images/cw-white.png';
import LogoColor from '../images/logo.svg';
import WhiteHam from '../images/hamburglar-white.svg';
import ColorHam from '../images/hamburglar.svg';
import HomeHeroImage from '../images/homeHero.jpg';

export const cwTheme = {
	primaryBlue: 'rgba(55, 197, 235, 1)',
	secondaryBlue: 'rgba(18, 160, 191, 1)',
	darkBlue: 'rgba(10, 123, 168, 1)',
	lightBlue: 'rgba(154, 218, 236, 1)',
	primaryHover: 'rgba(55, 197, 235, .75);',
	secondaryHover: 'rgba(18, 160, 191, .75)',
	noColor: 'rgba(1, 1, 1, 0)',
	black: 'rgba(61, 61, 61, 1)',
	blackHover: 'rgba(61, 61, 61, .5)',
	white: 'rgba(255, 255, 255, 1)',
	whiteHover: 'rgba(255, 255, 255, .5)',
	serif: '"Merriweather", serif',
	sansSerif: '"Source Sans Pro", sans-serif',
	xLargeBreakPoint: '95em',
	largeBreakPoint: '75em',
	mediumBreakPoint: '62em',
	mediumSmallBreakPoint: '50rem',
	smallBreakPoint: '32rem',
	whiteLogo: LogoWhite,
	colorLogo: LogoColor,
	whiteHamburger: WhiteHam,
	colorHamburger: ColorHam,
	homeHeroImage: HomeHeroImage,
	borderRadius: '2px;',
	boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1);',
	border: '1px solid #e4e4e4;'
};
