import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import { cwTheme } from '../theme/theme.js';
import Facebook from '../images/facebook.svg';
import Instagram from '../images/instagram.svg';
import YouTube from '../images/youtube.svg';
import LinkedIn from '../images/linkedin.svg';

const FooterWrapper = styled.footer`
	background-color: ${cwTheme.primaryBlue};
	color: rgba(255, 255, 255, 1);
`;

const FooterContainer = styled.div`
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 1rem;
`;

const InnerWrapper = styled.div`
	flex-direction: column;
	text-align: center;
	padding: 2rem 0;
	display: flex;

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		flex-direction: row;
		justify-content: space-around;
		text-align: left;
		padding: 5rem 0;
	}
`;

const FooterBlurb = styled.span`
	margin: 0 auto 1rem auto;
	font-size: 1.611111em;
	max-width: 500px;
	line-height: 42px;
	font-family: 'Merriweather', serif;

	display: flex;
	align-items: center;

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		margin: 0;
	}
`;

const FooterNav = styled.div`
	h4 {
		margin-bottom: 0;
		font-weight: 500;
	}
`;

const FooterLinks = styled.ul`
	list-style: none;
	padding-left: 0;
	margin-top: 0;

	a {
		color: ${cwTheme.white};
		text-decoration: none;
	}
	a:hover {
		color: ${cwTheme.whiteHover};
	}
`;

const FooterCopyright = styled.span`
	margin-bottom: 1rem;
	display: block;
	text-align: center;
`;

const FooterSocial = styled.div`
	margin: 0 auto;
	max-width: 500px;
	width: 100%;
	display: flex;
	justify-content: center;
	a {
		padding: 10px;
	}

	a:hover {
		img {
			color: ${cwTheme.secondaryHover};
		}
	}
`;

const ListLink = (props) => (
	<li>
		<Link to={props.to}>{props.children}</Link>
	</li>
);

const Footer = () => {
	const data = useStaticQuery(graphql`
		query {
			contentfulFooter {
				footerText
			}
			allContentfulPost(limit: 3, sort: { fields: publishDate, order: DESC }) {
				edges {
					node {
						slug
						title
					}
				}
			}
		}
	`);
	return (
		<FooterWrapper>
			<FooterContainer>
				<InnerWrapper>
					<FooterBlurb>{data.contentfulFooter.footerText}</FooterBlurb>

					<FooterNav>
						<h6>EXPLORE</h6>
						<FooterLinks>
							<ListLink to="/offerings">Offerings</ListLink>
							<ListLink to="/ondemand">On Demand</ListLink>
							<ListLink to="/blog">Blog</ListLink>
							<ListLink to="/about">About</ListLink>
							<ListLink to="/contact">Contact</ListLink>
						</FooterLinks>
					</FooterNav>

					<FooterNav>
						<h6>RECENT POSTS</h6>
						<FooterLinks>
							{data.allContentfulPost.edges.map(({ node: post }) => (
								<ListLink to={'blog/' + post.slug}>{post.title}</ListLink>
							))}
						</FooterLinks>
					</FooterNav>
				</InnerWrapper>
				<FooterSocial>
					<a href={'https://www.facebook.com/connectwellnessfb'} target="_blank">
						<img alt="Facebook" src={Facebook} />
					</a>
					<a href={'https://www.instagram.com/connectwellness_/'} target="_blank">
						<img alt="Instagram" src={Instagram} />
					</a>
					<a href={'https://www.linkedin.com/company/connectwellness/'} target="_blank">
						<img alt="LinkedIn" src={LinkedIn} />
					</a>
				</FooterSocial>
				<FooterCopyright>&copy; 2020 Connect Wellness</FooterCopyright>
			</FooterContainer>
		</FooterWrapper>
	);
};

export default Footer;
