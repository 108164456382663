import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import { cwTheme } from '../theme/theme.js';
import Container from './Container';
import Hamburger from './Hamburger';
import MobileMenu from './MobileMenu';

const backgroundColor = theme('mode', {
	light: cwTheme.white,
	dark: cwTheme.noColor
});

const myActiveColor = theme('mode', {
	light: cwTheme.primaryBlue,
	dark: cwTheme.secondaryBlue
});

const textColor = theme('mode', {
	light: cwTheme.darkBlue,
	dark: cwTheme.white
});

const textHover = theme('mode', {
	light: cwTheme.secondaryHover,
	dark: cwTheme.whiteHover
});

const position = theme('mode', {
	light: 'relative',
	dark: 'absolute'
});

const HeaderWrapper = styled.header`
	background-color: ${backgroundColor};
	position: ${position};
	width: 100%;
`;

const MainNav = styled.nav`
	justify-content: space-between;
	display: flex;
	align-items: center;
	height: 57px;
	padding: .5rem;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		justify-content: flex-start;
		padding: 1rem;
	}
`;

const LogoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const NavLogo = styled.img.attrs((props) => ({
	src: props.theme.mode == 'dark' ? cwTheme.whiteLogo : cwTheme.colorLogo
}))`
	max-width: 210px;
	width: 100%;
	

`;

const NavList = styled.ul`
	display: none;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		align-items: center;
		display: flex;
		justify-content: space between;
		list-style: none;
		margin-left: auto;
	}

	li {
		margin-left: 2rem;
	}
	a {
		text-decoration: none;
		color: ${textColor};
		font-weight: 600;
	}
	a:hover {
		color: ${textHover};
	}
`;

const ListLink = (props) => (
	<li>
		<Link
			activeStyle={{ color: cwTheme.secondaryHover }}
			partiallyActive={true}
			style={{ textShadow: 'none' }}
			to={props.to}
		>
			{props.children}
		</Link>
	</li>
);

const Header = () => {
	const [ open, setOpen ] = useState(false);

	return (
		<HeaderWrapper>
			<Container>
				<MainNav>
					<LogoWrapper>
						<Link to={'/'}>
							<NavLogo />
						</Link>
					</LogoWrapper>
					<NavList>
						<ListLink to="/offerings">Offerings</ListLink>
						<ListLink to="/ondemand">On Demand</ListLink>
						<ListLink to="/live">Live</ListLink>
						<ListLink to="/blog">Blog</ListLink>
					</NavList>
					<Hamburger open={open} setOpen={setOpen} />
					<MobileMenu open={open} setOpen={setOpen} />
				</MainNav>
			</Container>
		</HeaderWrapper>
	);
};
export default Header;
